import { Component, NgZone, OnInit } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { CheckoutService } from '../checkout.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';

declare var Razorpay: any;

@Component({
  selector: 'rbl-payment',
  templateUrl: './rbl-payment.component.html',
  styleUrls: ['./rbl-payment.component.scss']
})
export class RblPaymentComponent extends KalgudiDestroyable implements OnInit {

  orderDetails: any;
  profileKey: string;
  private hiddenElements: any[] = [];

  constructor(private spinner: SpinnerService,
    private checkout: CheckoutService,
    private authService: AuthenticationService,
    private ngZone: NgZone ) { 
      super();
    }

  ngOnInit(): void {
    this.orderDetails = window.history.state
    this.profileKey = this.authService.userData ? this.authService.userData.profileKey : '';
    
    if (this.orderDetails) {
      this.payWithRazorpay(this.orderDetails.data)
    }

    this.hideElementsById('smartsupp-widget-container');
    this.hideElementsByClassName('common-footer');
    this.hideElementsByClassName('top-header');
    this.hideElementsByClassName('side_link');
  }

  protected onDestroyed(): void {
    this.restoreHiddenElements();
  }

  /**
   * Method for calling razor pay gateway
   * @param data 
   */
  payWithRazorpay(data: any) {
    const options = {
      key: this.profileKey === 'M01l3cs0PRFREG2018123132244091UNH001' ? 'rzp_test_K7oxejdjM3EctU': 'rzp_live_xsWKpt9N1kVl52',
      amount: data.amount_due,
      currency: 'INR',
      name: 'Kalgudi',
      description: data.receipt,
      appOrderId: data.receipt,
      image: 'https://store.kalgudi.com/assets/images/kl-red-logo.png',
      order_id: data.id,
      
      handler: (response: any) => {
        this.ngZone.run(() => {
          this.spinner.start();
        });
        
        let paymentData: any = response;
        paymentData.orderId = data.receipt;

        // Convert the object to JSON string
        let jsonString = JSON.stringify(paymentData);

        // Encode the JSON string using btoa
        let base64EncodedString = btoa(jsonString);
        let payload = {
          encResp: base64EncodedString
        };

        this.checkout.paymentRblPaymentStatus(payload)
        .subscribe((res: any) => {
          this.ngZone.run(() => {
            this.spinner.stop();
          });
            let url = res.url;
            window.location.href = url;
          },
          (error: any) => {
            this.ngZone.run(() => {
              this.spinner.stop();
            });
            
            console.log("Error: ", error);
            let url = 'https://store.kalgudi.com/'
            window.location.href = url;
          }
        );
      },

      modal: {
        ondismiss: () => {
          console.log('Transaction cancelled.');
          let url = 'https://store.kalgudi.com/'
          window.location.href = url; 
        }
      },
      prefill: {
        name: data.notes.buyerName,
        email: 'chekuru.murali@kalgudi.com',
        contact: data.notes.buyerMobileNo
      },
      notes: {
        address: data.notes.buyerAddress
      },
      theme: {
        color: '#60be74'
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.open();
  }

  /**
   * This method for hiding header section
   */
  private hideElementsByClassName(className: string) {
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      // Store the initial display property to restore it later
      this.hiddenElements.push({ element, display: element.style.display });
      element.style.display = 'none';
    }
  }

  /**
   * This method to hide the section by using id
   * @param id
   */
  private hideElementsById(id: string) {
    const element = document.getElementById(id);
    if (element) {
      this.hiddenElements.push({ element, display: element.style.display });
      element.style.display = 'none';
    }
  }

  /**
   * This method for restoring header section
   */
  private restoreHiddenElements() {
    for (const hiddenElement of this.hiddenElements) {
      const { element, display } = hiddenElement;
      element.style.display = display || '';
    }
    this.hiddenElements = [];
  }

}
